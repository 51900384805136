<template>
  <!-- 画像zoom用 -->
  <b-container fluid>
    <b-row class="py-2">
      <b-col> クリックで拡大縮小・移動・回転ができます。</b-col>
      <b-col class="text-right">
        <b-button v-on:click="closeWindow" size="sm" class="w-25"
          >&times; close</b-button
        >
      </b-col>
    </b-row>
    <viewer v-bind:images="imgPaths" v-bind:options="options">
      <b-img
        thumbnail
        fluid
        v-for="src in imgPaths"
        v-bind:key="src"
        v-bind:src="src"
        class="mb-3"
      ></b-img>
    </viewer>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      options: { title: false, scalable: false, fullscreen: false },
      imgPaths: this.$route.query.path,
    }
  },
  methods: {
    closeWindow() {
      window.open('about:blank', '_self').close()
    },
  },
}
</script>
